import { create, defineComponent, html, type Props } from '../../component';
import { mergeClasses, namespaced } from '../../utilities/classes';
import { Icon } from '../icon/icon';

export interface StepperProps extends Props<HTMLDivElement> {
    /**
     * Callback for incrementing the stepper value.
     */
    readonly onIncrement?: (e: MouseEvent) => void;

    /**
     * The description for the increment action. Used for accessibility.
     *
     * Defaults to `'Increment'`.
     */
    readonly incrementDescription?: string;

    /**
     * Ref callback to receive the increment button element instance.
     */
    readonly incrementRef?: (element: HTMLButtonElement) => void;

    /**
     * Callback for decrementing the stepper value.
     */
    readonly onDecrement?: (e: MouseEvent) => void;

    /**
     * The description for the decrement action. Used for accessibility.
     *
     * Defaults to `'Decrement'`.
     */
    readonly decrementDescription?: string;

    /**
     * Ref callback to receive the decrement button element instance.
     */
    readonly decrementRef?: (element: HTMLButtonElement) => void;
}

export const Stepper = defineComponent(
    'Stepper',
    () =>
        ({
            onIncrement,
            onDecrement,
            incrementDescription = 'Increment',
            decrementDescription = 'Decrement',
            incrementRef,
            decrementRef,
            ...props
        }: StepperProps): HTMLElement =>
            create(
                'div',
                {
                    props: {
                        ...props,
                        className: mergeClasses(props.className, namespaced('m-stepper')),
                    },
                },
                html('button')(
                    {
                        className: namespaced('m-stepper__button', 'm-stepper__button--inc'),
                        ref: incrementRef,
                        onClick: onIncrement,
                        title: incrementDescription,
                    },
                    Icon({ name: 'arrow-up', description: incrementDescription }),
                ),
                html('button')(
                    {
                        className: namespaced('m-stepper__button', 'm-stepper__button--dec'),
                        ref: decrementRef,
                        onClick: onDecrement,
                        title: decrementDescription,
                    },
                    Icon({ name: 'arrow-down', description: decrementDescription }),
                ),
            ),
);
