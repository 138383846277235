export class UnmountObserver {
    #nodes: Map<Node, AbortController> = new Map();

    public constructor(root: HTMLElement) {
        new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                const numRemoved = mutation.removedNodes.length;
                for (let i = 0; i < numRemoved; i++) {
                    const node = mutation.removedNodes[i]!;
                    const controller = this.#nodes.get(node);
                    if (controller) {
                        controller.abort();
                        this.#nodes.delete(node);
                    }
                }
            }
        }).observe(root, { subtree: true, childList: true });
    }

    public observe(node: Node, controller?: AbortController): AbortSignal {
        if (this.#nodes.has(node)) {
            const signal = this.#nodes.get(node)!.signal;
            if (controller && signal !== controller.signal) {
                signal.addEventListener('abort', () => controller!.abort(), { once: true });
            }
            return signal;
        }
        if (!controller) {
            controller = new AbortController();
        }
        this.#nodes.set(node, controller);
        return controller.signal;
    }
}
