import { round } from './round';

/**
 * The arguments for a fluid clamp calculation.
 */
export interface CSSFluidClampArguments {
    /**
     * The minimum bound in pixels of the clamped value.
     */
    readonly min: number;

    /**
     * The maximum bound in pixels of the clamped value.
     */
    readonly max: number;

    /**
     * The minimum size in pixels of the relative responsive container (by default, the viewport width).
     *
     * At this size, the clamped value will be equal to the minimum bound.
     */
    readonly minSize: number;

    /**
     * The maximum size in pixels of the relative responsive container (by default, the viewport width).
     *
     * At this size, the clamped value will be equal to the maximum bound.
     */
    readonly maxSize: number;

    /**
     * The unit of the resulting clamped value. Defaults to `rem`.
     */
    readonly unit?: 'px' | 'rem';

    /**
     * The unit of the relative responsive container. Defaults to `vw` (i.e, the viewport width).
     *
     * If set to `cqi`, the clamped value will be relative to the container's content width.
     */
    readonly relativeUnit?: 'vi' | 'vw' | 'cqi';

    /**
     * The number of decimal places to round the factors to. Defaults to 4.
     */
    readonly precision?: number;
}

/**
 * Represents a CSS `clamp()` function factors for fluid typography and spacing.
 *
 * The values should be used as follows:
 *
 * ```scss
 * font-size: clamp($min, $base + $scalingRate, $max);
 * ```
 */
export interface CSSFluidClamp {
    /**
     * The minimum bound of the clamped value as a tuple of length and a unit.
     */
    readonly min: [value: number, unit: string];

    /**
     * The maximum bound of the clamped value as a tuple of length and a unit.
     */
    readonly max: [value: number, unit: string];

    /**
     * The base value of the clamped value as a tuple of length and a unit.
     */
    readonly base: [value: number, unit: string];

    /**
     * The scaling rate of the clamped value as a tuple of length and a unit.
     */
    readonly scalingRate: [value: number, unit: string];
}

/**
 * The default number of pixels per rem.
 *
 * Note: hard-coding this value technically makes generated clamps not responsive
 * in regards to the user-configurable base font size. But since the base font size
 * is rarely changed, this is a reasonable compromise.
 */
export const PIXELS_PER_REM = 16;

/**
 * Calculates the factors for a CSS `clamp()` function for fluid typography and spacing.
 *
 * @param args - The fluid clamp arguments.
 * @returns The fluid clamp factors.
 * @see https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
 */
export function cssFluidClamp({
    min: lo,
    max: hi,
    minSize,
    maxSize,
    unit = 'rem',
    relativeUnit = 'vw',
    precision = 4,
}: CSSFluidClampArguments): CSSFluidClamp {
    const scale = unit === 'px' ? 1 : PIXELS_PER_REM;

    const isDecline = lo > hi;
    const from = isDecline ? hi : lo;
    const to = isDecline ? lo : hi;

    const slope = (hi - lo) / (maxSize - minSize);

    const minValue = round(from / scale, precision);
    const maxValue = round(to / scale, precision);
    const baseValue = round((1 / scale) * (-minSize * slope + lo), precision);
    const scalingRateValue = round(slope * 100, precision);

    return {
        min: [minValue, unit],
        max: [maxValue, unit],
        base: [baseValue, unit],
        scalingRate: [scalingRateValue, relativeUnit],
    };
}

/**
 * Returns a string representation of a CSS `clamp()` function for fluid typography and spacing.
 *
 * @param args - The fluid clamp arguments.
 * @returns The CSS text for the `clamp()` function.
 */
export function cssFluidClampString(args: CSSFluidClampArguments) {
    const clamp = cssFluidClamp(args);
    const value = (name: keyof CSSFluidClamp) => clamp[name].join('');
    return `clamp(${value('min')}, ${value('base')} + ${value('scalingRate')}, ${value('max')})`;
}
