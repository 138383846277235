import './styles/index.scss';
import { withNamespace } from './utilities/classes.ts';

export * from './component.ts';
export * from './config.ts';
export * from './modules';
export * from './utilities';

export interface InitOptions {
    /**
     * Whether to insert the scope class to the body element.
     */
    insertScope?: boolean;

    /**
     * Whether to load the fonts.
     */
    loadFonts?: boolean;
}

const defaultOptions: InitOptions = {
    insertScope: false,
    loadFonts: true,
};

if (globalThis.document) {
    const config = Object.assign(
        {} as InitOptions,
        defaultOptions,
        globalThis['__MT_ui_config__' as keyof typeof globalThis] as InitOptions | undefined,
    ) as Required<InitOptions>;

    if (config.insertScope) {
        document.body.classList.add(withNamespace('s-app'));
    }

    if (config.loadFonts) {
        const fonts = [
            'Poppins:ital,wght@0,100;0,400;0,500;0,600;0,700;1,400',
            'Rubik:ital,wght@0,300..900;1,300..900',
        ].join('&family=');
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `https://fonts.googleapis.com/css2?family=${fonts}&display=swap`;
        document.head.appendChild(link);
    }
}
