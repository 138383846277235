import {
    defineComponent,
    forwardRef,
    html,
    render,
    type Component,
    type Props,
    type RefObject,
    type Renderable,
} from '../../component';
import { mergeClasses, withNamespace } from '../../utilities/classes';
import { ToastBar } from './bar';

export type { ToastBarControls, ToastBarProps } from './bar';

export interface ToastControls {
    /**
     * Set the content of the toast.
     *
     * @param content - The new content of the toast.
     */
    setContent(this: void, content: Renderable): void;

    /**
     * Set the title of the toast.
     *
     * @param title - The new title of the toast.
     */
    setTitle(this: void, title: Renderable): void;

    /**
     * Dismiss the toast.
     */
    dismiss(this: void): void;
}

export interface ToastProps extends Omit<Props<HTMLDivElement>, 'title'> {
    readonly title?: Renderable;
    readonly content: Renderable;
    readonly variant?: 'default' | 'success' | 'warning' | 'error';
    readonly isImportant?: boolean;
    readonly dismiss?: 'click' | { after: number } | undefined;
    readonly onDismiss?: () => void;
    readonly controls?: (controls: ToastControls) => void;
}

const toast = defineComponent(
    'Toast',
    () =>
        ({
            content,
            title: titleContent = null,
            variant = 'default',
            isImportant = typeof titleContent === 'string' && /error/i.test(titleContent),
            dismiss: dismissStrategy,
            onDismiss,
            controls,
            ...props
        }: ToastProps): Component<HTMLDivElement> => {
            const div = html('div');

            const ref: RefObject<HTMLDivElement> = { current: null };
            const contentRef: RefObject<HTMLDivElement> = { current: null };
            const titleRef: RefObject<HTMLDivElement> = { current: null };

            const title = div(
                { ref: titleRef, className: { 'm-toast__title': true } },
                titleContent,
            );

            function dismiss(this: void) {
                if (ref.current) {
                    ref.current.addEventListener(
                        'transitionend',
                        () => {
                            ref.current!.remove();
                            if (onDismiss) {
                                onDismiss();
                            }
                        },
                        { once: true },
                    );

                    ref.current.classList.add(withNamespace('is-dismissed'));
                }
            }

            if (controls) {
                controls({
                    setContent(content: Renderable) {
                        if (contentRef.current) {
                            const element = render(content);
                            if (element) {
                                contentRef.current.replaceChildren(element);
                            }
                        }
                    },
                    setTitle(newTitle: Renderable) {
                        if (!ref.current || newTitle === undefined) {
                            return;
                        }
                        if (!titleContent) {
                            ref.current.prepend(title.with({ ref: titleRef }).render());
                        }
                        titleContent = newTitle;
                        if (!titleContent) {
                            if (titleRef.current) {
                                titleRef.current.remove();
                            }
                        } else if (titleRef.current) {
                            const element = render(titleContent);
                            if (element) {
                                titleRef.current.replaceChildren(element);
                            }
                        }
                    },
                    dismiss,
                });
            }

            return div(
                {
                    ...props,
                    role: 'alert',
                    ariaAtomic: 'true',
                    ariaLive: isImportant ? 'assertive' : 'polite',
                    ref: forwardRef(props.ref, ref, (element: HTMLDivElement) => {
                        if (dismissStrategy === 'click' || props.onClick) {
                            element.style.setProperty('cursor', 'pointer');
                        }
                        if (typeof dismissStrategy === 'object') {
                            setTimeout(dismiss, dismissStrategy.after);
                        }
                    }),
                    className: mergeClasses(props.className, {
                        'm-toast': true,
                        [`m-toast--${variant}`]: variant !== 'default',
                    }),
                    onClick:
                        dismissStrategy === 'click'
                            ? (e) => {
                                  if (props.onClick) {
                                      props.onClick(e);
                                  }
                                  dismiss();
                              }
                            : props.onClick,
                },
                titleContent && title,
                div({ ref: contentRef, className: { 'm-toast__content': true } }, content),
            );
        },
);

export const Toast = Object.assign(toast, { Bar: ToastBar });
