import {
    defineComponent,
    forwardRef,
    html,
    type Component,
    type Props,
    type Ref,
} from '../../component';
import { mergeClasses, withNamespace } from '../../utilities/classes';
import { Toast, type ToastControls, type ToastProps } from './toast';

export interface ToastBarControls {
    /**
     * Create and show a new toast.
     */
    showToast(this: void, toast: ToastProps): ToastControls;
}

export interface ToastBarProps extends Props<HTMLDivElement> {
    /**
     * Callback to receive the toast bar controls.
     */
    controls(this: void, controls: ToastBarControls): void;

    readonly position?: 'fixed' | 'absolute';
}

export const ToastBar = defineComponent(
    'Toast.Bar',
    () =>
        ({ controls, position = 'fixed', ...props }: ToastBarProps): Component<HTMLDivElement> =>
            html('div')({
                ...props,
                className: mergeClasses(props.className, { 'm-toast-bar': true }),
                ref: forwardRef(props.ref, (element: HTMLElement) => {
                    const toastBlockSize = `--${withNamespace('toast-block-size')}`;
                    const toastTransitionDuration = `--${withNamespace('toast-transition-duration')}`;

                    element.style.setProperty('position', position);

                    controls({
                        showToast(toast) {
                            const controlsRef: Ref<ToastControls> = { current: null };
                            const toastElement = Toast({
                                ...toast,
                                controls: (toastControls) => {
                                    controlsRef.current = toastControls;
                                    if (toast.controls) {
                                        toast.controls(toastControls);
                                    }
                                },
                            }).render();

                            toastElement.style.setProperty(toastTransitionDuration, '0s');

                            element.appendChild(toastElement);

                            const { offsetHeight } = toastElement;

                            toastElement.style.setProperty(toastBlockSize, '0');
                            toastElement.style.setProperty('opacity', '0');

                            setTimeout(() => {
                                toastElement.style.removeProperty(toastTransitionDuration);

                                setTimeout(() => {
                                    toastElement.addEventListener(
                                        'transitionend',
                                        () => {
                                            toastElement.style.setProperty(toastBlockSize, '100%');
                                        },
                                        { once: true },
                                    );
                                    toastElement.style.setProperty(
                                        toastBlockSize,
                                        `${offsetHeight}px`,
                                    );
                                    toastElement.style.removeProperty('opacity');
                                }, 100);
                            }, 0);

                            return controlsRef.current!;
                        },
                    });
                }),
            }),
);
