import { create, defineComponent, type Props } from '../../component';
import { mergeClasses } from '../../utilities/classes';
import { FrameSection } from './section';

export interface FrameProps extends Props<HTMLDivElement> {
    readonly variant?: 'default' | 'small';
}

const frame = defineComponent(
    'Frame',
    () =>
        ({ variant = 'default', ...props }: FrameProps): HTMLDivElement =>
            create('div', {
                props: {
                    ...props,
                    className: mergeClasses(props.className, {
                        'l-cover': true,
                        'l-box': true,
                        'l-stack': true,
                        'm-frame': true,
                        [`m-frame--${variant}`]: variant !== 'default',
                    }),
                },
            }),
);

export const Frame: typeof frame & {
    readonly Section: typeof FrameSection;
} = Object.assign(frame, {
    Section: FrameSection,
});
