/**
 * The listeners map for a given HTMLElement.
 */
export type ListenersMap<T extends HTMLElement> = {
    [K in {
        [K in keyof T]: K extends `on${infer E}` ? E : never;
    }[keyof T] as `on${Capitalize<K>}${
        | ''
        | `_Capture${'' | 'Passive'}${'' | 'Once'}`
        | `_Passive${'' | 'Once'}`
        | '_Once'}`]?: ((event: HTMLElementEventMap[K]) => void) | undefined;
};

/**
 * Attaches event listeners to the given element.
 *
 * Each attached event listener will prevent the default event behavior.
 *
 * If a key ends with `_Once`, the event listener will only be called once, and then removed.
 *
 * If a key ends with `_Passive`, the event listener will be registered as passive.
 *
 * If a key ends with `_Capture`, the event listener will be registered as capturing.
 *
 * The special suffixes can be combined in Capture/Passive/Once order. For example,
 * `scroll_CapturePassiveOnce` or `click_CaptureOnce`.
 *
 * @param element - The HTMLElement to attach the event listeners to.
 * @param listeners - The map of event listeners to attach. The key is the event name with optional suffixes.
 * @param abortSignal - The optional signal to remove all given event listeners when aborted.
 * @returns The given element reference.
 */
export function addEventListeners<T extends HTMLElement>(
    element: T,
    listeners: ListenersMap<T>,
    abortSignal?: AbortSignal,
): T {
    for (const type of Object.keys(listeners)) {
        const listener = listeners[type as keyof ListenersMap<T>] as EventListener | undefined;
        if (typeof listener !== 'function') {
            continue;
        }

        const options: AddEventListenerOptions = {};

        if (abortSignal) {
            options.signal = abortSignal;
        }

        // eslint-disable-next-line prefer-const
        let [eventType, suffix = ''] = type.slice(2).toLowerCase().split('_', 2) as [
            string,
            string | undefined,
        ];

        if (suffix.endsWith('once')) {
            options.once = true;
            suffix = suffix.slice(0, -4);
        }

        if (suffix.endsWith('passive')) {
            options.passive = true;
            suffix = suffix.slice(0, -7);
        }

        if (suffix.endsWith('capture')) {
            options.capture = true;
        }

        element.addEventListener(eventType, listener, options);
    }

    return element;
}
