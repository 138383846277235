import { create, defineComponent, type Props } from '../../component';
import { mergeClasses } from '../../utilities/classes';

export interface FrameSectionProps extends Props<HTMLDivElement> {
    readonly variant?: 'default' | 'subtle';
}

export const FrameSection = defineComponent(
    'Frame.Section',
    () =>
        ({ variant = 'default', ...props }: FrameSectionProps): HTMLDivElement =>
            create('div', {
                props: {
                    ...props,
                    className: mergeClasses(props.className, {
                        'l-box': true,
                        'l-stack': true,
                        'm-frame__section': true,
                        [`m-frame__section--${variant}`]: variant !== 'default',
                    }),
                },
            }),
);
