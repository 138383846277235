import {
    defineComponent,
    forwardRef,
    html,
    type Component,
    type Props,
    type RefObject,
    type Renderable,
} from '../../component';
import { mergeClasses, withNamespace } from '../../utilities/classes';

export interface Tab {
    readonly value: string;
    readonly label: Exclude<Renderable, null | false | undefined>;
    readonly id?: string;
    readonly panelId?: string;
}

export interface TabControls {
    selectTab(this: void, value: string): void;
    setEnabled(this: void, enabled: boolean): void;
}

export interface TabsProps extends Omit<Props<HTMLDivElement>, 'onChange' | 'children'> {
    readonly tabs: Tab[];
    readonly value?: string;
    readonly disabled?: boolean;
    readonly onChange?: (value: string) => void;
    readonly controls?: (controls: TabControls) => void;
}

export const Tabs = defineComponent(
    'Tabs',
    () =>
        ({
            tabs,
            value,
            disabled = false,
            onChange,
            controls,
            ...props
        }: TabsProps): Component<HTMLDivElement> => {
            if (!value && tabs.length > 0) {
                value = tabs[0]!.value;
            }

            const ref: RefObject<HTMLDivElement> = { current: null };

            const tablist: TabButton[] = [];
            for (const tab of tabs) {
                tablist.push(
                    new TabButton(
                        ref,
                        tablist,
                        tab.value,
                        tab.label,
                        tab.id,
                        tab.panelId,
                        tab.value === value,
                    ),
                );
            }

            if (controls) {
                controls({
                    selectTab(newValue) {
                        const tab = tablist.find((tab) => tab.value === newValue);
                        if (tab) {
                            tab.isSelected = true;
                        }
                    },
                    setEnabled(enabled) {
                        if (ref.current) {
                            ref.current.classList.toggle(withNamespace('is-disabled'), !enabled);
                        }
                        for (const tab of tablist) {
                            tab.enable(enabled);
                        }
                    },
                });
            }

            return html('div')(
                {
                    ...props,
                    role: 'tablist',
                    className: mergeClasses(props.className, {
                        'm-tabs': true,
                        'is-disabled': disabled,
                    }),
                    ref: forwardRef(props.ref, ref, (element: HTMLDivElement) => {
                        element.style.setProperty(
                            `--${withNamespace('tab-count')}`,
                            String(tablist.length),
                        );
                    }),
                },
                ...tablist.map((tab, index) =>
                    html('button')(
                        {
                            id: tab.htmlId,
                            role: 'tab',
                            ariaSelected: tab.isSelected ? 'true' : null,
                            'aria-controls': tab.panelId,
                            tabIndex: index > 0 ? -1 : undefined,
                            className: { 'm-tabs__tab': true },
                            disabled,
                            ref: (element: HTMLButtonElement) => tab.bind(element, onChange),
                        },
                        html('span')({ className: { 'm-tabs__label': true } }, tab.label),
                    ),
                ),
            );
        },
);

class TabButton {
    #owner: RefObject;
    #tablist: ReadonlyArray<TabButton>;
    #element: HTMLButtonElement | null;
    #emitChange: ((value: string) => void) | undefined;
    #isSelected: boolean;

    constructor(
        owner: RefObject,
        tablist: ReadonlyArray<TabButton>,
        readonly value: string,
        readonly label: Renderable,
        readonly htmlId = `${value}-${Math.random().toString(36).slice(2)}`,
        readonly panelId?: string,
        isSelected = false,
    ) {
        this.#owner = owner;
        this.#tablist = tablist;
        this.#element = null;
        this.#isSelected = isSelected;
    }

    public get isSelected(): boolean {
        return this.#isSelected;
    }

    public set isSelected(value: boolean) {
        if (this.#isSelected === value) {
            return;
        }

        if (!value) {
            if (this.#element) {
                this.#element.ariaSelected = null;
                this.#element.tabIndex = -1;
            }
        } else {
            const prevSelected = this.#tablist.find((tab) => tab.isSelected);

            if (prevSelected) {
                prevSelected.isSelected = false;
            }

            if (this.#owner.current) {
                this.#owner.current.style.setProperty(
                    `--${withNamespace('active-tab')}`,
                    this.#tablist.indexOf(this).toString(),
                );
            }

            if (this.#element) {
                this.#element.ariaSelected = 'true';
                this.#element.removeAttribute('tabindex');
            }
        }

        this.#isSelected = value;
    }

    public bind(element: HTMLButtonElement, onChange?: (value: string) => void): void {
        this.#element = element;
        this.#emitChange = onChange;
        element.addEventListener('click', this.#onClick);
        element.addEventListener('keydown', this.#onKeyDown);
    }

    public enable(enabled: boolean): void {
        if (this.#element) {
            this.#element.disabled = !enabled;
        }
    }

    #onClick = (e: MouseEvent): void => {
        e.preventDefault();
        if (!this.isSelected) {
            this.isSelected = true;
            if (this.#emitChange) {
                this.#emitChange(this.value);
            }
        }
    };

    #onKeyDown = (e: KeyboardEvent): void => {
        let focusIndex: number;
        const tabIndex = this.#tablist.indexOf(this);
        const lastIndex = this.#tablist.length - 1;

        switch (e.key) {
            case 'ArrowLeft':
                focusIndex = tabIndex === 0 ? lastIndex : tabIndex - 1;
                break;

            case 'ArrowRight':
                focusIndex = tabIndex === lastIndex ? 0 : tabIndex + 1;
                break;

            case 'Home':
                focusIndex = 0;
                break;

            case 'End':
                focusIndex = lastIndex;
                break;

            default:
                return;
        }

        e.preventDefault();
        e.stopPropagation();
        this.#tablist[focusIndex]!.#element?.focus();
    };
}
