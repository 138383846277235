import { applyClasses, mergeClasses, withNamespace, type ClassName } from './classes';
import type { KebabCase } from './types';

/**
 * Allowed value for a CSS property.
 *
 * Either a string, or an object with a `var` key and an optional `fallback` key.
 *
 * The object form is used to specify a variable getter `var(name, fallback)`, but
 * the name (specified without `--` prefix) will be properly namespaced.
 */
export type CSSPropValue = string | { var: string; fallback?: CSSPropValue };

/**
 * Represent all settable properties of an HTML element.
 *
 * It also includes all `data-*` attributes.
 */
export type HTMLProps<T extends HTMLElement> = {
    /**
     * The CSS custom properties to apply to the element.
     */
    readonly cssProps?: Record<string, CSSPropValue>;

    /**
     * The inline CSS styles to apply to the element.
     */
    readonly css?: {
        [prop in
            | 'float'
            | KebabCase<
                  Exclude<
                      keyof CSSStyleDeclaration & string,
                      | 'cssFloat'
                      | 'cssText'
                      | 'length'
                      | 'parentRule'
                      | 'item'
                      | 'setProperty'
                      | 'getPropertyValue'
                      | 'getPropertyPriority'
                      | 'removeProperty'
                  >
              >]?: CSSPropValue;
    };

    /**
     * The class names to apply to the element, either as an object, a string or an array of both.
     *
     * The class names in object form will be namespaced with the configured prefix.
     */
    readonly className?: ClassName;
} & {
    readonly [P in `${'data' | 'aria'}-${string}`]?: string;
} & {
    [K in keyof T as IsReadonlyKey<T, K> extends true
        ? never
        : K extends `on${string}` | 'className'
          ? never
          : T[K] extends (...args: any[]) => any
            ? never
            : K]?: T[K] | undefined;
};

/**
 * Evaluates to `true` if the given type has at least one required property.
 */
export type HasRequiredProps<T> = keyof {
    [K in keyof T as IsOptionalKey<T, K> extends true ? never : K]: K;
} extends never
    ? false
    : true;

/**
 * Resolves { var: 'name' [, fallback: <value> ] } css prop values.
 *
 * @param value - The CSS prop value to stringify.
 * @returns The stringified CSS prop value.
 */
function handleCSSPropValue(value: CSSPropValue): string {
    if (typeof value === 'string') {
        return value;
    }
    const fallback = 'fallback' in value ? `, ${handleCSSPropValue(value.fallback)}` : '';
    return `var(--${withNamespace(value.var)}${fallback})`;
}

/**
 * Applies the given properties to the given element.
 *
 * All `data-*` props will be set via `dataset` and their values will be converted to strings.
 *
 * @param element - The element to apply the properties to.
 * @param props - The properties to apply.
 * @returns The given element reference.
 */
export function setProps<T extends HTMLElement>(element: T, props: HTMLProps<T>): T {
    for (const name of Object.keys(props)) {
        const value = props[name as keyof HTMLProps<T>];

        if (value === undefined) {
            continue;
        }

        const isProp = name === 'cssProps';
        if (isProp || name === 'css') {
            const css = value as Record<string, CSSPropValue>;
            for (const prop of Object.keys(css)) {
                element.style.setProperty(
                    isProp ? `--${withNamespace(prop)}` : prop,
                    handleCSSPropValue(css[prop]!),
                );
            }
            continue;
        }

        if (name === 'className') {
            applyClasses(element, mergeClasses(props.className));
            continue;
        }

        if (name.startsWith('data-')) {
            element.dataset[
                name
                    .slice(5)
                    .toLowerCase()
                    .replace(/-(.)/g, (_, ch: string) => ch.toUpperCase())
            ] = String(value);
            continue;
        }

        if (name in element) {
            element[name as keyof T] = value as T[keyof T];
        } else {
            element.setAttribute(name, String(value));
        }
    }
    return element;
}

/**
 * Resolves to `true` if the `A` and `B` types are identical.
 */
export type IsEqual<A, B> =
    (<G>() => G extends A ? 1 : 2) extends <G>() => G extends B ? 1 : 2 ? true : false;

/**
 * Resolves to `true` the given key of `T` is read-only.
 */
export type IsReadonlyKey<T, K extends keyof T> = IsEqual<
    { readonly [_ in K]: T[K] },
    { [_ in K]: T[K] }
>;

/**
 * Resolves to `true` if the given key of `T` is optional.
 */
export type IsOptionalKey<T, K extends keyof T> = IsEqual<{ [_ in K]?: T[K] }, { [_ in K]: T[K] }>;
