/**
 * Rounds a number to a specified number of decimal places.
 *
 * @param num - The number to round.
 * @param decimalPlaces - The number of decimal places to round to. Defaults to 2.
 * @returns The rounded number.
 */
export function round(num: number, decimalPlaces = 2): number {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round((num + Number.EPSILON) * factor) / factor;
}
