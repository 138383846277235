import { addEventListeners, type ListenersMap } from './utilities/event';
import { setProps, type HasRequiredProps, type HTMLProps } from './utilities/props';

type RenderableItem<T> =
    | (T extends HTMLElement ? Component<T> : never)
    | T
    | string
    | false
    | number
    | null
    | undefined;

/**
 * A thing that can be rendered to an HTMLElement instance.
 */
export type Renderable<T extends HTMLElement | void = HTMLElement> = T extends void
    ? void
    : RenderableItem<T> | ReadonlyArray<RenderableItem<T>>;

/**
 * A reference to some value, typically an HTMLElement instance.
 */
export interface RefObject<T = HTMLElement> {
    current: T | null;
}

export type Ref<T> = ((element: any) => void) | RefObject<T>;

/**
 * The base props type for a component.
 */
export type Props<
    T extends HTMLElement = HTMLElement,
    C extends HTMLElement | void = HTMLElement,
> = {
    readonly children?: ReadonlyArray<Renderable<C>>;
    readonly ref?: Ref<T> | undefined;
} & ListenersMap<T> &
    HTMLProps<T>;

/**
 * Represents a component instance with bound props and render methods.
 */
export interface Component<T extends HTMLElement = HTMLElement> {
    /**
     * The properties of the component instance.
     */
    readonly props: Props<T>;

    /**
     * Returns a clone of this component instance with the given properties merged
     * with the existing properties ("shallow" merge is done, i.e., props given to `with()`
     * will overwrite existing props with the same key).
     *
     * @param props - The properties to merge with the existing properties.
     */
    with(props: Props<T>): Component<T>;

    /**
     * Renders the component to an HTMLElement instance and appends it to the given parent element.
     *
     * @param parent -  The parent element to append the component to.
     *                  Either a selector string or an HTMLElement instance.
     * @returns The component's HTMLElement instance.
     */
    renderTo(parent: string | HTMLElement): T;

    /**
     * Renders the component to an HTMLElement instance.
     */
    render(): T;
}

/**
 * A component that can be rendered to an HTMLElement instance.
 */
export interface ComponentFactory<
    Name extends string = string,
    T extends HTMLElement = HTMLElement,
    C extends HTMLElement | void = HTMLElement,
    P extends Props<T, C> = Props<T, C>,
> {
    /**
     * The type (i.e., name) of the component.
     */
    readonly type: Name;

    /**
     * Creates a renderable component instance.
     *
     * @param props - The properties to apply to the component.
     * @param children  - The children elements to append to the component.
     *                    If props.children is defined, this will be appended to the props.children array.
     */
    (props: MaybeOptionalProps<P>, ...children: Renderable<C>[]): Component<T>;
}

type ChildrenElements<T extends Props<any>> = T extends Props<any, infer U> ? U : void;

type ElementType<T> = T extends HTMLElement | Text ? T : T extends Component<infer U> ? U : never;

type MaybeOptionalProps<T> = HasRequiredProps<T> extends true ? T : T | null | void;

/**
 * The context object passed to the render function provider.
 */
export interface DefineComponentContext {}

/**
 * Creates a Component out of the given render function.
 *
 * @param name - The name of the component.
 * @param renderProvider - The render function provider.
 * @returns The ElementRenderer instance.
 */
export function defineComponent<
    Name extends string,
    Renderer extends (props: any) => any,
    T extends HTMLElement = ElementType<ReturnType<Renderer>>,
>(
    name: Name,
    renderProvider: (context: DefineComponentContext) => Renderer,
): ComponentFactory<Name, T, ChildrenElements<Parameters<Renderer>[0]>, Parameters<Renderer>[0]> {
    type C = ChildrenElements<Parameters<Renderer>[0]>;
    type P = Parameters<Renderer>[0];
    const createComponent: ComponentFactory<Name, T, C, P> = Object.assign(
        {
            [name]: (props: MaybeOptionalProps<P>, ...children: Renderable<C>[]) => {
                const givenProps = (props ?? {}) as P;
                const existingChildren: unknown = givenProps.children;
                const instanceProps: P = {
                    ...givenProps,
                    children: (existingChildren
                        ? (Array.isArray(existingChildren)
                              ? existingChildren
                              : [existingChildren]
                          ).concat(children)
                        : children) as Renderable<C>[],
                };
                return {
                    props: instanceProps,
                    with(newProps: P): Component<T> {
                        return createComponent({ ...instanceProps, ...newProps });
                    },
                    render(): T {
                        const { ref, ...restProps } = instanceProps;
                        const element = render(renderProvider({})(restProps)) as T;

                        if (ref) {
                            if (typeof ref === 'function') {
                                (ref as (...args: any[]) => any)(element);
                            } else {
                                (ref as RefObject<T>).current = element;
                            }
                        }

                        return element;
                    },
                    renderTo(parent: string | HTMLElement): T {
                        const parentElement =
                            typeof parent === 'string' ? document.querySelector(parent) : parent;

                        const instance = this.render();

                        if (parentElement) {
                            parentElement.appendChild(instance);
                        } else {
                            console.error(
                                `Failed to render ${name} component: parent element not found`,
                                parent,
                            );
                        }

                        return instance;
                    },
                };
            },
        }[name]!,
        { type: name },
    );

    return createComponent;
}

export function forwardRef<T extends HTMLElement>(...refs: Array<Ref<T> | undefined>): Ref<T> {
    for (const ref of refs) {
        if (ref && typeof ref !== 'function') {
            ref.current = null;
        }
    }
    return (element: T) => {
        for (const ref of refs) {
            if (ref) {
                if (typeof ref === 'function') {
                    ref(element);
                } else {
                    ref.current = element;
                }
            }
        }
    };
}

/**
 * HTMLElement component factory cache.
 * @internal
 */
const htmlComponentCache = new Map<
    keyof HTMLElementTagNameMap,
    ComponentFactory<keyof HTMLElementTagNameMap, any, any, any>
>();

/**
 * Creates a new component factory for the given HTML element.
 *
 * Intended usage is to simplify the creation of components for basic HTML elements.
 *
 * @example
 * ```typescript
 * const ul = html('ul');
 * const li = html('li');
 * const component = ul({ children: [
 *      li({ children: ['Item 1'] }),
 *      li({ children: ['Item 2'] }),
 * ] });
 * ```
 *
 * @param element - The HTML tag name to create a component factory for.
 * @returns The component factory for the given HTML element.
 */
export function html<T extends keyof HTMLElementTagNameMap>(
    element: T,
): ComponentFactory<T, HTMLElementTagNameMap[T]> {
    if (!htmlComponentCache.has(element)) {
        htmlComponentCache.set(
            element,
            defineComponent(
                element,
                () => (props: Props<HTMLElementTagNameMap[T]>) => create(element, { props }),
            ),
        );
    }

    return htmlComponentCache.get(element)! as ComponentFactory<T, HTMLElementTagNameMap[T]>;
}

/**
 * Options for creating a new HTMLElement instance.
 */
export interface CreateOptions<T extends HTMLElement> {
    /**
     * The options to use when creating the element.
     */
    readonly options?: ElementCreationOptions;

    /**
     * The props to apply to the element.
     */
    readonly props?: Props<T>;
}
/**
 * Create a new HTMLElement instance with the given tag name.
 *
 * @param tagName - The tag name of the element to create.
 * @param options - The options to use when creating the element.
 * @returns The new HTMLElement instance.
 */
export function create<K extends keyof HTMLElementTagNameMap>(
    tagName: K,
    { options, props }: CreateOptions<HTMLElementTagNameMap[K]> = {},
    ...children: Renderable[]
): HTMLElementTagNameMap[K] {
    const element = document.createElement(tagName, options);

    if (props) {
        const { ref, children: propsChildren, ...rest } = props;

        const restProps = Object.keys(rest) as Array<
            Exclude<keyof Props<HTMLElementTagNameMap[K]>, 'children' | 'ref'> & string
        >;

        if (restProps.length > 0) {
            const listeners: ListenersMap<HTMLElementTagNameMap[K]> = {};
            const htmlProps: HTMLProps<HTMLElementTagNameMap[K]> = {};

            for (const key of restProps) {
                const value = rest[key];
                if (key.startsWith('on')) {
                    (listeners as Record<string, unknown>)[key] = value;
                } else {
                    (htmlProps as Record<string, unknown>)[key] = value;
                }
            }

            setProps(element, htmlProps);
            addEventListeners(element, listeners);
        }

        for (const child of [...(propsChildren ?? []), ...children]) {
            const childElement = render(child);
            if (childElement) {
                element.appendChild(childElement);
            }
        }

        if (ref) {
            if (typeof ref === 'function') {
                ref(element);
            } else {
                ref.current = element;
            }
        }
    }

    return element;
}

/**
 * Renders the given renderable to a Node instance.
 *
 * @param renderable - The renderable to render.
 * @returns The rendered element, or null if the renderable should not be rendered.
 */
export function render<T extends Renderable>(renderable: T): Node | null {
    if (Array.isArray(renderable)) {
        if (renderable.length === 0) {
            return null;
        }
        const fragment = document.createDocumentFragment();
        for (const child of renderable) {
            const childElement = render(child);
            if (childElement) {
                fragment.appendChild(childElement);
            }
        }
        return fragment;
    }

    let value: RenderableItem<T> = renderable;
    if (typeof value === 'number') {
        if (Number.isNaN(value)) {
            return null;
        }
        value = String(value);
    }
    if (!value) {
        return null;
    }
    if (typeof value === 'string') {
        return document.createTextNode(value);
    }
    return value instanceof HTMLElement ? value : (value as Component).render();
}
