/**
 * Monkey Tilt UI Library Configuration
 */
export const config = {
    /**
     * CSS "namespace" - a prefix to add to all class names and custom properties.
     *
     * This is used to reduce the chance of name collisions when
     * embedding the library in pages with existing styles.
     *
     * Can be set via the `MT_UI_CSS_NAMESPACE` environment variable.
     *
     * @default '-MT-'
     */
    ns: process.env.MT_UI_CSS_NAMESPACE ?? '-MT-',
};
