import { create, defineComponent, html, type Props } from '../../component';
import { mergeClasses, namespaced } from '../../utilities/classes';

/**
 * The available icon names.
 */
export type IconName =
    | 'arrow-up'
    | 'arrow-down'
    | 'ask'
    | 'cards'
    | 'chevron'
    | 'circle-dollar'
    | 'clockwise'
    | 'coins'
    | 'double-check'
    | 'flag'
    | 'lock'
    | 'music'
    | 'notepad'
    | 'plus'
    | 'raise'
    | 'sound'
    | 'stop';

export interface IconProps extends Props<HTMLElement> {
    /**
     * The icon name.
     */
    readonly name: IconName;

    /**
     * The optional icon description, used for accessibility.
     */
    readonly description?: string;
}

export const Icon = defineComponent(
    'Icon',
    () =>
        ({ name, description, ...props }: IconProps): HTMLElement => {
            if (description) {
                props = {
                    ...props,
                    children: [
                        description &&
                            html('span')({
                                children: [description],
                                className: namespaced('u-hide-visually'),
                            }),
                    ],
                };
            }
            return create('i', {
                props: {
                    ...props,
                    className: mergeClasses(
                        props.className,
                        namespaced('m-icon', `m-icon--${name}`),
                    ),
                },
            });
        },
);
