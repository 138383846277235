import { config } from '../config.ts';

/**
 * The class names to apply to the element, either as an object, a string or an array of both.
 *
 * The class names in object form will be namespaced with the configured prefix.
 */
export type ClassName =
    | string
    | ReadonlyArray<string | Record<string, boolean>>
    | Record<string, boolean>;

/**
 * Applies (or removes) all the classes to the given element.
 *
 * The class names in an object form are automatically prefixed
 * with the configured namespace. The plain strings are left as is.
 *
 * If the classes object has a key with a truthy value, the class is added.
 * Otherwise, the class is removed.
 *
 * @param element - The element to apply the classes to.
 * @param classes - The classes to apply.
 * @returns The element with the classes applied.
 */
export function applyClasses<T extends HTMLElement>(
    element: T,
    classes: ReadonlyArray<string | Record<string, boolean>>,
): T {
    const classNameMap: Record<string, boolean> = {};

    for (const className of classes) {
        if (typeof className === 'string') {
            element.classList.add(className);
        } else {
            Object.assign(classNameMap, className);
        }
    }

    for (const key in classNameMap) {
        if (classNameMap[key]) {
            element.classList.add(withNamespace(key));
        } else {
            element.classList.remove(withNamespace(key));
        }
    }

    return element;
}

/**
 * Merges the given class names into a single array, for use with `applyClasses()`.
 *
 * @param classes - The classes to merge.
 * @returns The merged classes object.
 */
export function mergeClasses(
    ...classes: Array<ClassName | undefined>
): ReadonlyArray<string | Record<string, boolean>> {
    const classList: Array<string | Record<string, boolean>> = [];

    for (const item of classes) {
        if (!item) {
            continue;
        }

        if (Array.isArray(item)) {
            classList.push(...(item as (string | Record<string, boolean>)[]));
            continue;
        }

        classList.push(item as string | Record<string, boolean>);
    }

    return classList;
}

/**
 * Turns the given class names into a namespaced classSet object.
 *
 * @param classNames - The class names to namespace.
 * @returns The namespaced classSet object.
 */
export function namespaced(...classNames: string[]): Record<string, boolean> {
    return classNames.reduce(
        (acc, className) => {
            acc[className] = true;
            return acc;
        },
        {} as Record<string, boolean>,
    );
}

/**
 * Returns the given name with the configured CSS namespace prepended.
 *
 * @param name - The class or custom property name (without `.` or `--` prefix).
 * @returns The given name with the configured namespace prepended.
 */
export function withNamespace(name: string): string {
    return `${config.ns}${name}`;
}
