import { create, defineComponent, html, type Props, type RefObject } from '../../component';
import { mergeClasses, namespaced } from '../../utilities/classes';
import { Icon, type IconName } from '../icon/icon';

export interface LabelControls {
    setText(this: void, text: string): void;
}

export interface LabelProps extends Props<HTMLLabelElement & HTMLSpanElement> {
    /**
     * The label text.
     *
     * Ignored if `children` is provided.
     */
    readonly text?: string;

    /**
     * The optional help text, renders as an icon with a tooltip.
     */
    readonly tooltip?: string;

    /**
     * The optional icon. If provided, the icon will be rendered before the text.
     */
    readonly icon?: IconName;

    /**
     * The optional icon description, used for accessibility.
     */
    readonly iconDescription?: string;

    /**
     * The label variant.
     */
    readonly variant?: 'default' | 'medium' | 'box' | 'subtle' | 'ribbon-l' | 'ribbon-r';

    /**
     * Callback to receive the label controls.
     */
    controls?: (controls: LabelControls) => void;
}

export const Label = defineComponent(
    'Label',
    () =>
        ({
            text,
            tooltip,
            icon,
            htmlFor,
            iconDescription = '',
            variant = 'default',
            children = [],
            controls,
            ...props
        }: LabelProps): HTMLLabelElement | HTMLSpanElement => {
            const ref: RefObject = { current: null };

            if (controls) {
                controls({
                    setText: (text) => {
                        if (ref.current) {
                            ref.current.textContent = text;
                        }
                    },
                });
            }

            return create(
                htmlFor ? 'label' : 'span',
                {
                    props: {
                        ...props,
                        htmlFor,
                        className: mergeClasses(props.className, {
                            'm-label': true,
                            [`m-label--${variant}`]: variant !== 'default',
                        }),
                    },
                },
                icon && Icon({ name: icon, description: iconDescription }),
                ...(children.length > 0
                    ? children
                    : [(text || controls) && html('span')({ ref }, text)]),
                tooltip &&
                    Icon({
                        name: 'ask',
                        title: tooltip,
                        role: 'tooltip',
                        className: namespaced('m-label__tooltip'),
                    }),
            );
        },
);
