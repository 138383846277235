import {
    create,
    defineComponent,
    forwardRef,
    html,
    type Component,
    type Props,
    type Renderable,
} from '../../component';
import { mergeClasses, namespaced, withNamespace } from '../../utilities/classes';
import type { ListenersMap } from '../../utilities/event';

export interface InputControls {
    update(newValue: string): void;
    ref(): HTMLInputElement | null;
}

export interface InputProps extends Props<HTMLInputElement> {
    /**
     * Prefix to display before the input.
     */
    prefix?: Renderable;

    /**
     * Suffix to display after the input.
     */
    suffix?: Renderable;

    /**
     * Action to display at the right edge of the input.
     */
    action?: Component;

    /**
     * Ref callback to receive the input element instance.
     */
    inputRef?: (element: HTMLInputElement) => void;

    /**
     * Callback to receive the action element instance.
     */
    actionRef?: (element: HTMLElement) => void;

    /**
     * Callback to receive the input controls.
     */
    controls?: (controls: InputControls) => void;

    /**
     * Mask function to apply to the input value.
     */
    mask?: (value: string, prevValue: string) => string;
}

const defaultMask = (value: string, _: string): string => value;

export const Input = defineComponent(
    'Input',
    () =>
        ({
            className,
            inputRef,
            actionRef,
            prefix,
            suffix,
            action,
            mask = defaultMask,
            controls,
            children,
            ...props
        }: InputProps): HTMLDivElement => {
            let container: HTMLDivElement | undefined;
            let sizer: HTMLDivElement | undefined;
            let input: HTMLInputElement | null = null;

            const observer = new ResizeObserver(() => {
                if (!container || !sizer) {
                    return;
                }
                container.style.setProperty(
                    `--${withNamespace('input-size')}`,
                    `${sizer.offsetWidth}px`,
                );
                container.style.setProperty(
                    `--${withNamespace('input-position')}`,
                    `${sizer.offsetLeft}px`,
                );
            });

            function update() {
                if (sizer && input) {
                    sizer.textContent = input.value;
                }
            }

            function focus(e: Event) {
                if (input && e.target !== input) {
                    input.focus();
                }
            }

            if (controls) {
                controls({
                    update(newValue) {
                        if (input) {
                            input.value = mask(newValue, input.value);
                            update();
                        }
                    },
                    ref() {
                        return input;
                    },
                });
            }

            let prevValue = props.value || '';

            const events = ['onChange', 'onInput', 'onPaste', 'onDrop'] as const;
            const listenerMap = events.reduce((map, event) => {
                map[event] = (e: Event): void => {
                    if (e.defaultPrevented || !(e.target instanceof HTMLInputElement)) {
                        return;
                    }
                    e.target.value = mask(e.target.value, prevValue);
                    update();
                    prevValue = e.target.value;
                    if (typeof props.onChange == 'function') {
                        props.onChange(e);
                    }
                };
                return map;
            }, {} as ListenersMap<HTMLInputElement>);

            return create(
                'div',
                {
                    props: {
                        className: mergeClasses(className, {
                            'm-input': true,
                        }),
                        tabIndex: 0,
                        onClick: focus,
                        onFocus: focus,
                        ref(element: HTMLDivElement) {
                            container = element;
                            observer.observe(element);
                        },
                    },
                },
                prefix && html('span')({ className: namespaced('m-input__affix') }, prefix),
                html('div')({
                    ref(element: HTMLDivElement) {
                        sizer = element;
                        observer.observe(element);
                        update();
                    },
                    className: {
                        'm-input__sizer': true,
                    },
                }),
                html('input')({
                    ...props,
                    ref(element: HTMLInputElement) {
                        if (inputRef) {
                            inputRef(element);
                        }
                        input = element;
                        update();
                        input.value = mask(input.value, prevValue);
                    },
                    ...listenerMap,
                    className: {
                        'm-input__input': true,
                    },
                }),
                html('span')({ className: namespaced('m-input__affix') }, suffix),
                children && html('div')({ className: namespaced('m-input__addons') }, ...children),
                action &&
                    action.with({
                        ref: forwardRef(action.props.ref, actionRef),
                        className: mergeClasses(action.props.className, {
                            'm-input__action': true,
                        }),
                    }),
            );
        },
);
