import { create, defineComponent, html, type Props } from '../../component';
import { mergeClasses, namespaced } from '../../utilities/classes';
import { Icon, type IconName } from '../icon/icon';

export interface ButtonProps extends Props<HTMLButtonElement & HTMLAnchorElement> {
    /**
     * The button label.
     */
    readonly label?: string;

    /**
     * The optional button icon.
     */
    readonly icon?: IconName;

    /**
     * The optional icon description, used for accessibility.
     */
    readonly iconDescription?: string;

    /**
     * The button variant.
     */
    readonly variant?: 'primary' | 'secondary' | 'tertiary';

    /**
     * Whether the button is a call-to-action button.
     */
    readonly cta?: boolean;

    /**
     * Whether the button is in "active" state. When used as a faux-toggle button.
     */
    readonly isActive?: boolean;
}

export const Button = defineComponent(
    'Button',
    () =>
        ({
            label,
            icon,
            iconDescription,
            variant = 'primary',
            cta = false,
            isActive = false,
            ...props
        }: ButtonProps): HTMLButtonElement | HTMLAnchorElement =>
            create(
                props.href ? 'a' : 'button',
                {
                    props: {
                        ...props,
                        className: mergeClasses(props.className, {
                            'm-button': true,
                            'is-active': isActive,
                            'm-button--cta': cta,
                            [`m-button--${variant}`]: variant !== 'primary',
                        }),
                    },
                },
                label && html('span')(null, label),
                icon &&
                    Icon({
                        name: icon,
                        description: iconDescription || (!label && props.title) || '',
                    }),
            ),
);

export type ButtonBarProps = Props<HTMLDivElement>;

export const ButtonBar = defineComponent(
    'ButtonBar',
    () =>
        (props: ButtonBarProps): HTMLDivElement =>
            create('div', {
                props: {
                    ...props,
                    className: mergeClasses(
                        props.className,
                        namespaced('l-cluster', 'u-gap--s', 'u-align--center'),
                    ),
                },
            }),
);
